

























































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Toast, Dialog } from "vant";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin]})
export default class UserBank extends Vue {
  public bank_list = []; // 银行卡列表
  public bank_index = -1; // 当前选择的银行卡
  public showPicker = false; // 是否显示银行选择框
  public value = ""; // 银行卡的值
  public name = ""; // 用户真实姓名
  public bankName = ""; // 用户真实姓名

  // 提交请求
  submit() {
    let _this = this;
    let bank_list: any = this.bank_list;
    this.$api.request({
      url: "user/bank/add-bank",
      data: {
        bank_id: bank_list[this.bank_index].bank_id,
        bank_account: this.value,
        bank_branch:this.bankName
      },
      success(res) {
        _this.$api.goBack();
      }
    });
  }

  // 选择银行
  bankPicker(index) {
    this.bank_index = index;
    this.showPicker = false;
  }
  // 切换银行选择
  cardChange(value) {
    let _this = this;
    axios({
      url:
        "https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=" +
        value +
        "&cardBinCheck=true"
    }).then(res => {
      let boolean = true;
      if (res.data.validated) {
        // 验证成功
        _this.bank_list.map((item: any, index: number) => {
          if (item.bank_sigillum === res.data.bank) {
            _this.bank_index = index;
            boolean = false;
          }
        });
        if (boolean) {
          Dialog.alert({
            title: "提示",
            message: "暂不支持该银行卡绑定"
          });
          _this.bank_index = -1;
        }
      }
    });
  }
  // 拍照绑定银行卡
  bankPhoto(file) {
    let _this = this;
    this.$api.uploadFile(file.file, res => {
      this.$api.request({
        url: "user/member/ocrbankcard",
        data: { img: res.data.url },
        success(res) {
          if (res.data) {
            // 识别银行卡号成功
            _this.bank_list.forEach((item: any, index: number) => {
              if (res.data[0].bank === item.bank_name) {
                _this.bank_index = index;
              }
            });
            _this.value = res.data[0].number;
            // 识别银行
            _this.cardChange(_this.value);
          } else {
            Toast("请上传银行卡照片");
          }
        }
      });
    });
  }
  // 获取银行卡列表
  getBankList() {
    let _this = this;
    this.$api.request({
      url: "common/bank/get-list",
      success(res) {
        _this.bank_list = res.data;
      }
    });
  }
  // 获取用户银行卡绑定信息
  getMember() {
    let _this = this;
    this.$api.request({
      url: "user/member/info",
      success(res) {
        _this.name = res.data.realname;
      }
    });
  }

  init() {
    this.bank_list = []
    this.bank_index = -1;
    this.showPicker = false;
    this.value = "";
    this.name = "";
    this.getBankList();
    this.getMember();
  }
}
